// http://ionicframework.com/docs/v2/theming/

// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

h1, h2, h3, h4, h5 {
  font-family: 'Albert Sans', 'HelveticaNeue', sans-serif;
  font-weight: 700 !important;
}

a, p {
  font-family: 'Albert Sans', 'HelveticaNeue', sans-serif;
  font-weight: 500;
}

strong, b {
  font-family: 'Albert Sans', 'HelveticaNeue', sans-serif;
  font-weight: 700;
}

// Checkbox styling

.inline-checkbox {
  background-color: transparent !important;
  padding-left: 0 !important;

  .label {
    white-space: normal !important;
    font-size: 1.4rem !important;
    margin: 0 !important;
  }
}

.item.item-md .checkbox-md {
  margin: 9px 16px 9px 4px;
}

.label-md {
  margin: 13px 8px;
}

// Label styling
ion-label.required:after {
  content: " *";
  color: red;
}

// Option to style radio buttons on iOS as checkboxes
.item.item-ios .input-wrapper + .checkbox-mode.radio-ios {
  margin: 8px 0 8px 8px;
}

.item.item-ios .checkbox-mode.radio-ios {
  @extend .checkbox-ios !optional;

  .radio-icon {
    @extend .checkbox-icon !optional;
  }

  .radio-checked {
    @extend .checkbox-checked !optional;

    .radio-inner {
      @extend .checkbox-inner !optional;
    }
  }

  &-primary {
    .radio-checked {
      border-color: var(--ion-color-green) !important;
      background-color: var(--ion-color-green) !important;

      .radio-inner {
        border-color: color-contrast(var(--ion-color-green)) !important;
      }
    }
  }

  &-secondary {
    .radio-checked {
      border-color: var(--ion-color-salmon) !important;
      background-color: var(--ion-color-salmon) !important;

      .radio-inner {
        border-color: color-contrast(var(--ion-color-salmon)) !important;
      }
    }
  }
}


// Remove double border bottom

.item-md.item-block .item-inner,
.item-md.item-input.input-has-focus .item-inner,
.item-md.item-input.ng-valid.input-has-value:not(.input-has-focus) .item-inner,
.item-md.item-input.ng-invalid.ng-touched:not(.input-has-focus) .item-inner,
.list-md .item-input.input-has-focus:last-child,
.list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):last-child,
.list-md .item-input.ng-invalid.ng-touched:not(.input-has-focus):last-child {
  border-bottom: none;
  box-shadow: none;
}

.text-input-wp {
  border: none !important;
}

// Android fix item font
.card-content-md {
  font-family: 'Albert Sans', 'HelveticaNeue', sans-serif;
  font-weight: 500;
}

// Menu styling

ion-menu {
  ion-list {
    --ion-item-background: var(--ion-color-light);
    padding-top: 0px !important;
  }

  ion-list-header {
    background-color: transparent !important;
    text-transform: none;
    letter-spacing: 0;
    font-weight: bold;
    font-size: 11px;
    border-top: 1px solid var(--ion-color-border);
  }

  ion-item {
    --background: var(--ion-color-white);
    --min-height: 35px;
    --inner-border-width: 0px;
    --padding-start: calc(var(--ion-safe-area-left, 0px) + 16px);
    font-size: 12px;
    cursor: pointer;
  }

  .version-label {
    color: var(--ion-color-text-light);
    padding: 20px;

    ion-icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .scroll-content {
    padding-top: 0 !important;
  }
}

@media (max-width: 768px) {
  .hidden-md {
    display: none;
  }
}

@media (min-width: 769px) {
  .hidden-md-up {
    display: none;
  }
}

.header-md:after {
  display: none;
}

.md {
  ion-toolbar.subheader-splitpane {
    --min-height: 41px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
}

.ios {
  ion-toolbar.subheader-splitpane {
    --min-height: 40px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    --border-width: 0px;
  }
}

.toolbar-background-ios {
  border: none !important;
}

.split-pane-ios.split-pane-visible > .split-pane-side {
  border-right: 1px solid #dedede;
}

ion-title {
  font-weight: 600;
  letter-spacing: 0px;

  img {
    height: 24px;
    margin-bottom: -6px;
    margin-right: 8px;
    width: auto;
    display: inline-block;
  }
}

ion-img {
  background: transparent;
}

ion-icon {
  height: 1.35em;
  width: 1.35em;
}

ion-item {
  --padding-start: 0px;

  .label-stacked {
    color: var(--ion-color-text-light) !important;
    font-size: 16px !important;

    &.ios {
      font-size: 12px !important;
    }
  }

  ion-label {
    width: auto;
  }
}

ion-list.item-lines {
  padding-top: 0;
  padding-bottom: 0;

  ion-item:last-child {
    --inner-border-width: 0px;
  }
}

hr {
  background: rgba(0, 0, 0, .08);
}

.center-image {
  margin: auto;
  display: block;
}

.alert-button-inner {
  justify-content: center !important;
}

.alert-modal, .alert-modal-question {
  .alert-wrapper {
    .alert-button-group {
      flex-wrap: wrap;
      padding: 0px 20px 20px 20px;

      button {
        font-family: 'Albert Sans', Helvetica, Arial, sans-serif !important;
        font-weight: 600;;
        font-size: 1em !important;
        text-transform: none;
        border: 0px;
        border-radius: 4px;
        width: 100%;
        margin-right: 0px !important; //Windows platform fix
        .button-inner {
          justify-content: center;
        }
      }
    }

    .alert-title {
      color: var(--ion-color-dark-green) !important;
    }

    .alert-message {
      padding-top: 10px;
      border: none !important;
      overflow: hidden;
      max-height: 300px;
    }
  }
}

.alert-modal .alert-wrapper .alert-button-group {
  button.alert-button:nth-child(1) {
    background-color: var(--ion-color-salmon) !important;
    color: var(--ion-color-white);
    border-radius: 12px;
  }

  button.alert-button:nth-child(2) {
    color: var(--ion-color-salmon);
  }
}

.alert-modal-question .alert-wrapper .alert-button-group {
  button.alert-button:nth-child(1), button.alert-button:nth-child(2), button.alert-button:nth-child(3) {
    border: 1px solid var(--ion-color-green);
    color: var(--ion-color-green);
    margin-bottom: 6px;
  }
}


.popclose {
  text-align: right;
  position: absolute;
  top: 3px;
  right: -4px;
  z-index: 1000;
  color: #029dff !important;
  padding: 0px !important;
  background-color: transparent !important;
}

.account-button {
  .icon-ios {
    font-size: 1.5em;
  }

  .icon-md {
    font-size: 1.35em;
  }
}

ion-card-content {
  color: var(--ion-color-dark-green) !important;
  background: var(--ion-color-medium) !important;
}

// Desktop styling
// Column styling
@media (min-width: 992px) {
  ion-col {
    max-width: 720px !important;
  }

  ion-card {
    margin: 0px 0px 18px 0px !important;
  }

  ion-card:first-of-type {
    margin: 12px 0px 18px 0px !important;
  }

  progress-bar .progress-bar-wrapper .progress-bar {
    margin: 6px 0px 12px 0px !important;
  }

  .button {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .button-margin {
    margin-top: 12px;
  }

  ion-navbar .toolbar-content {
    text-align: center;
  }

  .scroll-content {
    overflow-y: auto;
  }
}

.capitalize {
  text-transform: capitalize;
}

.toast-message {
  white-space: pre-wrap;
}

.underline {
  text-decoration: underline;
}

// smartbanner
.smartbanner {
  position: initial !important;
}

.smartbanner.smartbanner--android {
  background: #ffffff !important;
  color: black !important;
  box-shadow: inset 0 4px 0 #88b131 !important;
}

.smartbanner__info {
  justify-content: center !important;
}

.smartbanner.smartbanner--android .smartbanner__info {
  text-shadow: none !important;
  color: #000000 !important;
  justify-content: center;
}

.smartbanner.smartbanner--android .smartbanner__info--title {
  color: #558b2f !important;
}

.smartbanner.smartbanner--android .smartbanner__button {
  box-shadow: none !important;
  color: #ffffff !important;
  border: 0 !important;
  background: #558b2f !important;
}

.smartbanner.smartbanner--android .smartbanner__button__label {
  background: #8bc34a !important;
}

.smartbanner.smartbanner--android .smartbanner__exit {
  background: #558b2f !important;
  box-shadow: none !important;
  top: 0 !important;
}

.smartbanner.smartbanner--android .smartbanner__exit::before, .smartbanner.smartbanner--android .smartbanner__exit::after {
  background: #ffffff !important;
}

.smartbanner.smartbanner--android .smartbanner__button__label:active, .smartbanner.smartbanner--android .smartbanner__button__label:hover {
  background: #8bc34a !important;
}

body.scanner-active {
  display: none;
}

ion-content.ios {
  pointer-events: auto !important; // to fix scroll lock on ios
}

//https://digibri.atlassian.net/browse/BOEK-1121
.safe-area-menu-item-start {
  margin-top: env(safe-area-inset-top);
}

//https://digibri.atlassian.net/browse/BOEK-1121
ion-content:not([fullscreen]) {
  --padding-bottom: env(safe-area-inset-bottom);
}

.back-btn {
  --border-radius: 12px !important;
  --background: rgba(var(--ion-color-dark-green-rgb), 0.1);
  --background-activated: rgba(var(--ion-color-dark-green-rgb), 0.1);
  height: 56px;
  width: 56px;
  position: absolute;
  top: calc(24px + env(safe-area-inset-top));
  left: 24px;
  color: var(--ion-color-dark-green);
  z-index: 2;
}

.btn-base {
  --border-radius: 12px !important;
  width: 100%;
  flex-direction: row;
  font-family: "Albert Sans", serif;
  font-size: 16px !important;
  font-weight: 700;
  height: 64px;
}

.disabled-btn {
  @extend .btn-base;
  color: #999 !important;
  --background: #CCCCCC !important;
}

.primary-btn {
  @extend .btn-base;
  --background: var(--ion-color-salmon);
  --background-hover: var(--ion-color-dark-salmon-tint);
  --background-activated: var(--ion-color-dark-salmon-shade);
}

.secondary-btn {
  @extend .btn-base;
  --background: var(--ion-color-dark-green);
  --background-hover: var(--ion-color-dark-green-tint);
  --background-activated: var(--ion-color-dark-green-shade);
}

.transparent-btn {
  @extend .btn-base;
  --background: rgba(var(--ion-color-dark-green-rgb), 0.2);
  --background-hover: rgba(var(--ion-color-dark-green-rgb), 0.15);
  --background-activated: rgba(var(--ion-color-dark-green-rgb), 0.25);
}

.auth-header {
  font-family: "Albert Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: var(--ion-color-dark-green);
  line-height: 40px;
  display: block;
}

.auth-img {
  height: 280px;
  width: 280px;
  margin: auto;
}

.auth-input {
  --placeholder-color: var(--ion-color-dark-green);
  text-decoration: none !important;
  border: 2px solid #DFDEDD;
  border-radius: 8px;
  text-align: left !important;
  padding: 6px 18px !important;
  background-color: rgba(var(--ion-color-dark-green-rgb), 0.1);
  width: 100%;
}

.auth-input.has-focus {
  border: 2px solid rgba(var(--ion-color-dark-green-rgb), 0.2);
}
